import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

typeof window !== "undefined" && require("bootstrap/js/dist/modal")

import NavBar from "../components/NavBar"
import Footer from "../components/Footer"
import Portfolio from "../components/Portfolio"
import Modal from "../components/Modal.js"
import { OutboundLink } from "../components/OutboundLink"

import "./about.scss"

class AboutPage extends React.Component {
  render() {
    return (
      <>
        <NavBar {...this.props} />
        <AboutContent gerente={this.props.gerente} />
        <Footer />
      </>
    )
  }
}

export function Head() {
  return (
    <>
      <title>Nosotros | GlobalAxxis</title>
      <meta
        name="description"
        content="Estamos construyendo un mundo limpio propulsado por energía solar. Explora nuestros producto y servicios."
      />
      <meta
        name="keywords"
        content="Energía solar, Eficiencia energética, Paneles solares"
      />
    </>
  )
}

function Page(props) {
  return (
    <StaticQuery
      query={graphql`
        query {
          gerente: file(relativePath: { regex: "/11.jpg/" }) {
            childImageSharp {
              gatsbyImageData(layout: FIXED)
            }
          }
        }
      `}
      render={data => <AboutPage gerente={data.gerente} {...props} />}
    />
  )
}
export default Page

const AboutContent = props => {
  const gerente = getImage(props.gerente)
  return (
    <section id="about" className="body container">
      <div className="text-center">
        <div className="brandname">
          <div className="brandname-main">
            Global<b>Axxis</b>
          </div>
          <div className="brandname-secondary">Energía Solar</div>
        </div>
        <h4>35 años de experiencia a tu servicio.</h4>
      </div>

      <div className="row">
        <div className="col-md-8 col-sm-12">
          <h3>Historia</h3>
          <p>
            GlobalAxxis fue fundada en 2005 por un grupo de ingenieros
            nacionales como una continuación de E&B Consultores en Energía
            establecida en 1988, quienes observaron la gran deficiencia del uso
            de las energías renovables no convencionales en la matriz eléctrica
            chilena, en contraste, a la abundancia y riqueza de este de tipo de
            energía disponible en el país.
          </p>

          <h3>Nuestra Misión</h3>
          <p>Acelerar la transición del país hacia la energía sostenible</p>
        </div>
        <div className="col-md-4 col-sm-12 b-col">
          <GatsbyImage image={gerente} alt="Gerente Desarrollo Energético" />

          <a href="\#" data-bs-toggle="modal" data-bs-target="#Modal">
            <h5>Guillermo Baltra Aedo</h5>
          </a>

          <Modal id="Modal" title="Guillermo Baltra Aedo">
            <div className="row">
              <div className="col-sm-4 text-center">
                <GatsbyImage
                  image={gerente}
                  alt="Gerente Desarrollo Energético"
                />
              </div>
              <div className="col-sm-8">
                <h4>Gerente de Desarrollo Energético y Fundador. </h4>
                <ul>
                  <li>
                    Fundador de Global Axxis y E&B Consultores en Energía.
                  </li>
                  <li>
                    Ingeniería en Energía Nuclear, Massachusetts Institute of
                    Technology (MIT).
                  </li>
                  <li>
                    Ingeniero Eléctrico Naval, Academia Politécnica Naval
                    (APOLINAV).
                  </li>
                  <li>
                    Catedrático de Sistemas Modernos de Energía en la Pontificia
                    Universidad Católica de Chile en Santiago y en la APOLINAV
                    en Viña del Mar.
                  </li>
                  <li>
                    Consultor para compañías públicas y privadas en el área de
                    estudios de energías. Ha llevado a cabo diferentes proyectos
                    con el operador de red local.
                  </li>
                  <li>
                    Director del Centro Naval de Estudios Avanzados CENESA.
                  </li>
                </ul>
              </div>
            </div>
          </Modal>

          <h5>Gerente de Desarrollo Energético y Fundador</h5>
        </div>
      </div>

      <div className="row">
        <div className="col-md-8 col-sm-12">
          <h3>Nuestros Valores</h3>
          <p>
            Para permitir un correcto desenvolvimiento de nuestra estrategia y,
            a la vez cumplir con nuestra misión, debemos tener claro no sólo el
            qué haremos, sino también el cómo lo haremos. A través de un proceso
            que involucra a todos los integrantes a lo largo de GlobalAxxis,
            definimos lo siguiente:
          </p>
          <ul className="bullet-list">
            <li>
              Centrado en el cliente – establecemos prósperas relaciones con
              nuestros clientes
            </li>
            <li>
              Cumplimos nuestros compromisos – hacemos lo que decimos que vamos
              a hacer
            </li>
            <li>
              Pasión por la excelencia – nos esforzamos por lograr el mejor
              resultado en todas nuestras actividades, diariamente
            </li>
            <li>
              Triunfamos juntos – desarrollamos, compartimos y utilizamos
              virtudes individuales para triunfar como equipo
            </li>
          </ul>

          <h3>Principios Fundamentales</h3>
          <p>
            Existen ciertos comportamientos que esperamos de parte de todos
            nuestros equipos. Les llamamos a estos principios fundamentales, o
            comportamientos intransables bajo toda circunstancia. Hemos definido
            tres - seguridad, integridad y sostenibilidad.
          </p>

          <h3>Nuestros Estándares</h3>
          <p>
            Nuestros principios de negocio establecen los estándares bajo los
            cuales operamos. Por sobre todo, estamos comprometidos en crear
            valor de largo plazo a nuestros clientes, inversionistas, equipos y
            sociedad en general.
          </p>

          <p>
            Preparamos a nuestros equipos bajo estos principios a lo largo del
            país, y continuamos actuando en consecuencia diariamente. De manera
            de mantenernos concentrados en esta línea, hemos creado el Código de
            Conducta de GlobalAxxis.
          </p>

          <p>
            Este Código se encuentra diseñado para proveer a nuestra gente de
            una clara idea de sus obligaciones. En esencia, captura no sólo los
            principios generales de negocio, sino también nuestras Directrices
            Corporativas más relevantes. Fundamentalmente, ayuda a nuestros
            equipos a realizar sus trabajos según los estándares de GlobalAxxis.
          </p>
        </div>
        <div className="col-md-4 col-sm-12">
          <div className="section-press">
            <div className="press-contacts">
              <h4 className="press-contacts-title">Prensa</h4>
              <address className="vcard">
                <div className="region">América del Sur</div>
                <div className="email">
                  <a href="mailto:prensa@globalaxxis.cl">
                    prensa@globalaxxis.cl
                  </a>
                </div>
              </address>
            </div>
            <div className="social-contact">
              <OutboundLink href="https://www.facebook.com/globalaxxisrenewables/">
                <i
                  className="fa-brands fa-facebook-f"
                  aria-label="Facebook"
                ></i>
              </OutboundLink>
              {/*<li>
                <a href="https://www.facebook.com/globalaxxisrenewables">
                  <i className="fa fa-facebook"></i></a>
              </li>
              {/*<li> <a href="#"><i className="fa fa-twitter"></i></a></li>
                 <li> <a href="#"><i className="fa fa-google-plus"></i> </a></li>
                 <li><a href="#"> <i className="fa fa-linkedin"></i></a></li>*/}
            </div>
          </div>
        </div>
      </div>
      <hr />

      <Portfolio />
    </section>
  )
}
