import React, { useLayoutEffect, useEffect, useState } from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { StaticQuery, graphql } from "gatsby"
import classnames from "classnames"
typeof window !== "undefined" && require("bootstrap/js/dist/carousel")

import "./portfolio.scss"

function useWindowSize() {
  const [size, setSize] = useState([0, 0])
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight])
    }
    window.addEventListener("resize", updateSize)
    updateSize()
    return () => window.removeEventListener("resize", updateSize)
  }, [])
  return size
}

export const Portfolio = props => {
  const [width, height] = useWindowSize()

  const concon1 = getImage(props.concon1)
  const lapena1 = getImage(props.lapena1)
  const lacalera1 = getImage(props.lacalera1)
  const lacalera2 = getImage(props.lacalera2)
  const pintue1 = getImage(props.pintue1)
  const vina4 = getImage(props.vina4)
  const hijuelas1 = getImage(props.hijuelas1)
  const romeral2 = getImage(props.romeral2)
  const sagradafamilia1 = getImage(props.sagradafamilia1)
  const aculeo1 = getImage(props.aculeo1)
  const cabildo1 = getImage(props.cabildo1)

  const carousel = React.useRef()
  const cardItem = React.useRef()

  const [cardWidth, setCardWidth] = useState(0)
  const [left, setLeft] = useState(0)
  const [blocked, setBlocked] = useState(false)

  useEffect(() => {
    setLeft(-cardItem.current.scrollWidth * 4)
  })

  useLayoutEffect(() => {
    function updateSize() {
      setCardWidth(cardItem.current.scrollWidth)
      if (width < 768) setLeft(0)
      else setLeft(-cardItem.current.scrollWidth * 4)
    }
    window.addEventListener("resize", updateSize)
    updateSize()
    return () => window.removeEventListener("resize", updateSize)
  }, [])

  const prevClick = e => {
    if (width < 768) {
      return
    }
    if (blocked) return

    setBlocked(true)

    let items = carousel.current.children
    let lastNode = items[items.length - 1]
    let firstNode = items[0]

    rotate(cardWidth)
    setTimeout(() => {
      firstNode.parentNode.insertBefore(lastNode, firstNode)
      setBlocked(false)
    }, 600)
  }

  const nextClick = e => {
    if (width < 768) {
      return
    }

    if (blocked) return

    setBlocked(true)
    let items = carousel.current.children
    let lastNode = items[items.length - 1]
    let firstNode = items[0]

    rotate(-cardWidth)
    setTimeout(() => {
      firstNode.parentNode.insertBefore(firstNode, lastNode.nextSibling)
      setBlocked(false)
    }, 600)
  }

  const rotate = nextPosition => {
    let items = carousel.current.children
    for (let j = 0; j < items.length; j++) {
      let el = items[j]
      el.animate(
        [
          // keyframes
          { transform: `translateX(0px)` },
          { transform: `translateX(${nextPosition}px)` },
        ],
        600
      ) //scroll left
    }
  }

  return (
    <div id="portfolio">
      <div className="col-md-12 col-sm-12 col-xs-12">
        <div className="text-center">
          <h3>
            Conoce algunos de nuestros <b>Proyectos</b>
          </h3>
        </div>
      </div>

      <div
        id="carouselExampleControls"
        className={classnames({ carousel: true, slide: width < 768 })}
        data-bs-ride="carousel"
      >
        <div className="carousel-inner" ref={carousel}>
          <div
            className="carousel-item"
            style={{ left: `${left}px` }}
            ref={cardItem}
          >
            <div className="card">
              <GatsbyImage
                image={concon1}
                alt="Paneles solares en Concón 1"
                className="d-block w-100"
              />
              <div className="card-body">
                <h5 className="card-title">Concón</h5>
                <p className="card-text">V Región</p>
              </div>
            </div>
          </div>
          <div className="carousel-item" style={{ left: `${left}px` }}>
            <div className="card">
              <GatsbyImage
                image={lapena1}
                alt="Paneles solares en La Peña"
                className="d-block w-100"
              />
              <div className="card-body">
                <h5 className="card-title">La Peña</h5>
                <p className="card-text">V Región</p>
              </div>
            </div>
          </div>
          <div className="carousel-item" style={{ left: `${left}px` }}>
            <div className="card">
              <GatsbyImage
                image={lacalera2}
                alt="Paneles solares en La Calera"
                className="d-block w-100"
              />
              <div className="card-body">
                <h5 className="card-title">La Calera</h5>
                <p className="card-text">V Región</p>
              </div>
            </div>
          </div>
          <div className="carousel-item" style={{ left: `${left}px` }}>
            <div className="card">
              <GatsbyImage image={pintue1} alt="Paneles solares en Pintué" />
              <div className="card-body">
                <h5 className="card-title">Pintué</h5>
                <p className="card-text">Región Metropolitana</p>
              </div>
            </div>
          </div>
          <div className="carousel-item" style={{ left: `${left}px` }}>
            <div className="card">
              <GatsbyImage
                image={vina4}
                alt="Paneles solares en Viña del Mar"
              />
              <div className="card-body">
                <h5 className="card-title">Viña del Mar</h5>
                <p className="card-text">V Región</p>
              </div>
            </div>
          </div>
          <div className="carousel-item" style={{ left: `${left}px` }}>
            <div className="card">
              <GatsbyImage
                image={hijuelas1}
                alt="Paneles solares en Hijuelas"
              />
              <div className="card-body">
                <h5 className="card-title">Hijuelas</h5>
                <p className="card-text">V Región</p>
              </div>
            </div>
          </div>
          <div className="carousel-item" style={{ left: `${left}px` }}>
            <div className="card">
              <GatsbyImage
                image={romeral2}
                alt="Paneles solares en El Romeral"
              />
              <div className="card-body">
                <h5 className="card-title">El Romeral</h5>
                <p className="card-text">VIII Región</p>
              </div>
            </div>
          </div>
          <div className="carousel-item active" style={{ left: `${left}px` }}>
            <div className="card">
              <GatsbyImage
                image={sagradafamilia1}
                alt="Paneles solares en Sagrada Familia"
              />
              <div className="card-body">
                <h5 className="card-title">Sagrada Familia</h5>
                <p className="card-text">VII Región</p>
              </div>
            </div>
          </div>
          <div className="carousel-item" style={{ left: `${left}px` }}>
            <div className="card">
              <GatsbyImage image={aculeo1} alt="Paneles solares en Aculeo" />
              <div className="card-body">
                <h5 className="card-title">Aculeo</h5>
                <p className="card-text">Región Metropolitana</p>
              </div>
            </div>
          </div>
          <div className="carousel-item" style={{ left: `${left}px` }}>
            <div className="card">
              <GatsbyImage image={cabildo1} alt="Paneles solares en Cabildo" />
              <div className="card-body">
                <h5 className="card-title">Cabildo</h5>
                <p className="card-text">V Región</p>
              </div>
            </div>
          </div>
          <div className="carousel-item" style={{ left: `${left}px` }}>
            <div className="card">
              <GatsbyImage
                image={lacalera1}
                alt="Paneles solares en La Calera 2"
              />
              <div className="card-body">
                <h5 className="card-title">La Calera</h5>
                <p className="card-text">V Región</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button
        className="carousel-control-prev"
        type="button"
        data-bs-target="#carouselExampleControls"
        data-bs-slide="prev"
        onClick={prevClick}
      >
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Previous</span>
      </button>
      <button
        className="carousel-control-next"
        type="button"
        data-bs-target="#carouselExampleControls"
        data-bs-slide="next"
        onClick={nextClick}
      >
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Next</span>
      </button>
    </div>
  )
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        vina4: file(relativePath: { regex: "/vina4.jpg/" }) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        hijuelas1: file(relativePath: { regex: "/hijuelas1.jpg/" }) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        romeral2: file(relativePath: { regex: "/romeral2.jpg/" }) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        sagradafamilia1: file(
          relativePath: { regex: "/sagradafamilia1.jpg/" }
        ) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        aculeo1: file(relativePath: { regex: "/aculeo1.jpg/" }) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        cabildo1: file(relativePath: { regex: "/cabildo1.jpg/" }) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        lacalera1: file(relativePath: { regex: "/lacalera1.jpg/" }) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        lacalera2: file(relativePath: { regex: "/lacalera2.jpg/" }) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        pintue1: file(relativePath: { regex: "/pintue1.jpg/" }) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        lapena1: file(relativePath: { regex: "/lapena1.jpg/" }) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        concon1: file(relativePath: { regex: "/concon1.jpg/" }) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    `}
    render={data => (
      <Portfolio
        vina4={data.vina4}
        hijuelas1={data.hijuelas1}
        romeral2={data.romeral2}
        sagradafamilia1={data.sagradafamilia1}
        aculeo1={data.aculeo1}
        cabildo1={data.cabildo1}
        lacalera1={data.lacalera1}
        lacalera2={data.lacalera2}
        pintue1={data.pintue1}
        lapena1={data.lapena1}
        concon1={data.concon1}
        {...props}
      />
    )}
  />
)
